/* website: 3758-mazda-toronto
 * created at 2022-07-19 17:30 by Rymma
 */
// Import all makes and organization styles files
@import "../templates/makes/mazda.scss";
@import "../utils/icons.scss";

@include breakpoint(mobile){
  .widget-sr {
    &.dealer__mazda-of-toronto {
      .LoginBanner{
        padding: 0px!important;
      }
      .LoginBanner .LoginBannerWrapper {
        height: 34px;
        max-height: 34px;
        .login-content {
          display: flex;
          padding-right: 0;
          #button-login,
          #button-register {
            font-family: MazdaTypeTT-Regular,Verdana,"sans-serif";
            font-size: 12px !important;
            .label{
              font-size: 14px !important;
            }
          }
          #button-login.btn-login {
            margin-left: 10px;
          }

          #button-register.btn-register {
            margin-left: 0;
            min-width: 110px !important;
            padding: 5px !important;
          }
        }
      }
      .sr-modal__wrapper{
        margin-top: 75px;
      }
    }
  }
}

@media (max-width: 387px){
  @include breakpoint(mobile){
    .widget-sr {
      &.dealer__mazda-of-toronto {
        .LoginBanner .LoginBannerWrapper {
          .login-content {
            #button-login,
            #button-register {
              .label{
                font-size: 13px !important;
              }
            }
          }
        }
        .sr-modal__wrapper{
          margin-top: 75px;
        }
      }
    }
  }
}